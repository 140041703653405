import { useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../../constants/data/data";
import { useEffect } from "react";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/dashboard");
  }, []);
  return (
    <div className="p-4  bg-white h-screen shadow-sm !z-10  ">
      {sidebarItems.map((item: any) => (
        <div
          className={` my-4 p-2 rounded cursor-pointer  text-sm font-light ${
            item.name.toLowerCase().includes(pathname.slice(1).toLowerCase()) &&
            pathname.slice(1) != ""
              ? "bg-[#FFF5F9]   font-semibold text-[#CB0E95]"
              : "text-gray-500"
          }`}
          onClick={() => {
            if (item.name.toLowerCase().includes("telegram")) {
              window.location.href = item.url;
            } else {
              navigate(item.url);
            }
          }}
        >
          {item.icon} {item.name}
        </div>
      ))}
    </div>
  );
};

export { Sidebar };
