import React, { FC, ReactEventHandler } from "react";

interface ButtonProps {
  text: any;
  onBtnClick?: ReactEventHandler;
  width?: number;
  Icon?: any;
  OverideStyle?: string;
  outlined?: true;
  disabled?:any;
  style?: any;
}

const Button: FC<ButtonProps> = ({
  outlined,
  text,
  onBtnClick,
  disabled,
  width,
  Icon,
  OverideStyle,
  style,
}) => {
  return (
    <button
    disabled={disabled}
      onClick={onBtnClick}
      type="button"
      className={`inline rounded-[8px] ${
        outlined ? " bg-faint text-primary" : `${disabled ?'bg-gray-300':'bg-primary'} text-white`
      } ${
        width ? "w-[" + width + "px]" : "w-full"
      } transition cursor-pointer duration-200  focus:ring-opacity-50 py-3  px-4   text-xs my-3 shadow-sm hover:shadow-md font-semibold border-primary text-center inline-block  border-black  ${style} scale-90 md:scale-100`}
       
    >
      <div className="flex items-center justify-center">
        {Icon && (
          <Icon
            size={20}
            className={`${
              outlined ? "text-primary  " : "text-white "
            } mr-2 ${OverideStyle}`}
          />
        )}{" "}
        <span className={`inline-block ${OverideStyle}`} text-center>
          {text}
        </span>
      </div>
    </button>
  );
};

export default Button;
