import axios from "axios";
import { baseURL } from "../utils/URL";
  

const onGenerateCurrencypairs = async ( postData:any) => {
    try {
      const response = await axios.post(`${baseURL}gen/getcurrencypair`, postData);
      return response.data;
    } catch (error: any) {
      console.log(error?.response);
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };
const onGenerateStrategy= async ( postData:any) => {
    try {
      const response = await axios.post(`${baseURL}gen/genstrategy`, postData);
      return response.data;
    } catch (error: any) {
      console.log(error?.response);
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };
  export{onGenerateCurrencypairs, onGenerateStrategy}
