import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useState, useEffect } from "react";
import { CiTimer } from "react-icons/ci";
import { MdOutlineBookmarkAdd, MdPreview } from "react-icons/md";
import CountDownTimerModal from "../../../../components/generic/CountDownTimerModal";
import { useStateGetter } from "../../../../hooks/statehooks/UseStateGettersHook";
import { useStateSetter } from "../../../../hooks/statehooks/UseStateSettersHook";
import { usePickerhook } from "../../../../hooks/usePickerhook";
import { onSaveScan } from "../../../../services/user";
import { Button } from "../../../../components/forms";
import { IoMdArrowBack } from "react-icons/io";

export default ({ nextStep, previousStep, postdata }: any) => {
  const TimeZoneselecthook = usePickerhook();
  const { setScandetails, setLoading } = useStateSetter();
  const { strategyres, NextStep, authuser,PrevStep } = useStateGetter();
  const { control, getValues } = useForm();
  const [showtimer, setshowtimer] = useState(false);

  const messages = [
    "Analysising strategy...",
    "Checking indicators...",
    "Fetching best strategy...",
  ];
  const [loadingmsg, setloadingmsg] = useState(messages[0]);
  const [result, setresults] = useState([]);

  const navigate = useNavigate();

  const SaveScan = async () => {
    let postddata = {
      market: "Strategy generator",
      date: new Date().toDateString(),
      timezone: "",
      starttime: postdata.timeframe,
      endtime: postdata.tradetime,
      content:strategyres ,
      userid: authuser.id,
    };
    setLoading(true);

    try {
      var res = await onSaveScan(postddata);
        toast.success("Saved for later");
        setTimeout(() => {
        setLoading(false);
        navigate("/dashboard");
      }, 2000);
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="border border-1 grid grid-cols-1 md:grid-cols-2  gap-4 w-min-[400px] md-w-[700px] md:mt-6 border-gray-300 rounded">
        <div className="  rounded bg-gray-200 text-sm font-light  p-2 ">
          <p className="">
            <b>Indicators:</b>
            <ol>
              <hr />{" "}
              {postdata.indicators?.map((kik: any) => (
                <li>{kik.name}</li>
              ))}
            </ol>
          </p>
          <p className="">
            <b>Time Frame:</b> {postdata.timeframe}
          </p>
          <p className="">
            <b> Trade Time: </b>
            {postdata.tradetime}
          </p>
        </div>

        <div className="bg-white rounded p-2 max-h-[250px] overflow-y-scroll overflow-x-hidden">
          <p className="text-md font-bold text-green-300">
            Result <FaCheck className="inline " />
          </p>
          <hr />

          <div dangerouslySetInnerHTML={{ __html: strategyres }} /> 
        </div>
      </div>
      <div className="w-full justify-items-end">
        <div className=" w-[80vw] md:w-[50%]  m-2 self-end flex justify-end items-center gap-3 md:gap-6">
          <Button
            text={
              <>
                <IoMdArrowBack className="inline" /> Go back
              </>
            }
            onBtnClick={() => {
              window.location.reload()
            }}
          />
             <Button
            outlined
            text={   <>
                <MdOutlineBookmarkAdd className="inline" /> save for later
              </>
            }
            onBtnClick={async () => {
              SaveScan();
            }}
          />
        </div>
      </div>
    </div>
  );
};
