import React, { useState } from 'react';
import "./index.css"
import "react-toastify/dist/ReactToastify.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./output.css"
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import AuthRoutes from './routes/AuthRoutes';
import Spinner from './components/generic/Spinner';
import { useStateGetter } from './hooks/statehooks/UseStateGettersHook';
function App() {



 const {isloading} =useStateGetter()



    return (
      <div className="overflow-y-hidden overflow-x-hidden w-screen h-screen bg-customGray ">
        <Spinner loading={isloading} />
     
        <AuthRoutes/>

         <ToastContainer /> 
    </div>
  );
}

export default App;
