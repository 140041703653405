import { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { useStateSetter } from "../../../../hooks/statehooks/UseStateSettersHook";
import SelectCountry from "../SubFlows/Market/SelectCountry";
import SelectMarket from "../SubFlows/Market/SelectMarket";
import SelectState from "../SubFlows/Market/SelectState";
import { indicators } from "../../../../constants/data/data";
import { NavigateBtns } from "../../../../components/generic/NavigateBtns";
import { CiStar } from "react-icons/ci";
import { RxStarFilled } from "react-icons/rx";
import { tab } from "@testing-library/user-event/dist/tab";
import { InputField } from "../../../../components/forms";
import Tooltip from "../../../../components/generic/Tooltip";
import { useForm } from "react-hook-form";

export default ({ nextStep, previousStep , setpostdata}: any) => {
  const { setNextAction, setPrevAction } = useStateSetter();
  const [mainActive, setmainActive] = useState<number>(1);
   const [favorites, setFavourites] = useState([]);
  const [activetab, setactivetab] = useState("");
  const { control, handleSubmit, getValues, watch } = useForm();
  const watchedFields = watch(["timeframe", "tradetime"]);

 
  const areFieldsFilled = watchedFields.every(
    (field) =>
      field !== "" &&
      field !== undefined   );
  useEffect(() => {
    setNextAction(nextStep);
    setPrevAction(previousStep);
  }, []);
 

  
 

  return (
    <div className="md:!w-[600px] mt-6">
    
      <div className="!md:w-full flex flex-col md:flex-row  justify-item items-center gap-4 rounded h-[200px] overflow-y-scroll ">
        <InputField
          name="timeframe"
          title="Input time frame"
          placeholder={"time frame"}
          tooltip={
            <Tooltip
              title="Time frame"
              content="Time frame for indicator (include unit e.g 3 minuites)"
            />
          }
          control={control}
        />
        <InputField
          name="tradetime"
          title="Input trade time "
          placeholder={"trade time"}
          tooltip={
            <Tooltip
              title="Trade time"
              content="Time interval for trading (include unit e.g 3 minuites)"
            />
          }
          control={control}
        />
         
      </div>
      <NavigateBtns
        shownext
        showprev
        actionPrev={() => {
          previousStep();
        }}
        nextCondition={areFieldsFilled}
        actionNext={() => {
          setpostdata((prev:any)=>{
            return{...prev , tradetime:getValues("tradetime"), timeframe:getValues("timeframe")}
          })
          nextStep();
        }}
      />
    </div>
  );
};
