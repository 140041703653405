import { CiTimer } from "react-icons/ci";
import { Button } from "../../components/forms";
import { MdClearAll, MdOutlineBookmarkAdd } from "react-icons/md";
import { IoMdQrScanner } from "react-icons/io";
import { GoHistory } from "react-icons/go";
import ScanItem from "../../components/generic/ScanItem";
import { onGetSaveScan } from "../../services/user";
import { useEffect, useState } from "react";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { useNavigate } from "react-router-dom";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import EmptyList from "../../components/generic/EmptyList";
import useSound from 'use-sound';

 export default () => {
  const navigate = useNavigate();

  const { setLoading } = useStateSetter();
  const [playbackRate, setPlaybackRate] =useState(0.75);
  const { authuser } = useStateGetter();
  const [savedscan, setsavedscan] = useState([]);

  const getuserSaved = async () => {
    let postdata = {
      userid: authuser.id,
    };

    setLoading(true);
    var res = await onGetSaveScan(postdata);
    setLoading(false);

    setsavedscan(res);
  };

  useEffect(() => {
    getuserSaved();
  }, []);
  return (
    <div className="w-full flex  flex-col">
      <div className=" w-full       bg-white    flex justify-between   items-start flex-col md:flex-row mb-4 ">
        <p   className="font-semibold text-gray-600 my-2  ">
          <GoHistory className="text-gray-400 mx-2 inline  " />
          Recently saved market scans
        </p>


      

        <div className="  w-full md:w-[40%] self-end md:p-6 flex gap-2  justify-center items-center  ">
          <Button
            width={200}
            outlined
            text={  <> <MdClearAll className="inline" />View all</>  }
            onBtnClick={() => { navigate("/allscans"); }}
          />

          <Button
            text={
              <>
                {" "}
                <IoMdQrScanner className="inline" /> Scan now{" "}
              </>
            }
            width={200}
            onBtnClick={() => {
              navigate("/scanner");
            }}
          />
        </div>
      </div>

      {savedscan.length != 0 ? (
        <div className="px-2 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-4 overflow-y-scroll overflow-x-hidden h-[70vh] py-2">
          {[...savedscan].reverse().slice(0, 6)?.map((item: any) => (
            <ScanItem refresh={getuserSaved} item={item} />
          ))}
        </div>
      ) : (
        <EmptyList />
      )}
    </div>
  );
};
