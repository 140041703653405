import { CiNoWaitingSign } from "react-icons/ci";


export default ()=>{



    return(<div className=" mx-4  bg-white flex justify-center flex-col items-center w-full h-[200px]">
<CiNoWaitingSign size={30} className="text-primary "/>
<p className="  font-light text-">No item found</p>

    </div>)
}