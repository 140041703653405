import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
 
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { NavigateBtns } from "../../../../components/generic/NavigateBtns";
import { useStateGetter } from "../../../../hooks/statehooks/UseStateGettersHook";
import { useStateSetter } from "../../../../hooks/statehooks/UseStateSettersHook";
import { usePickerhook } from "../../../../hooks/usePickerhook";
import { onGenerateCurrencypairs, onGenerateStrategy } from "../../../../services/generate";

export default ({ nextStep, previousStep, postdata }: any) => {
  const TimeZoneselecthook = usePickerhook();
  const { setStrategyresult } = useStateSetter();
  const [isloading, setisloading] = useState(false);

  const { scandetails, NextStep, PrevStep } = useStateGetter();
  const {setScanresult} =useStateSetter()
  const { control, getValues } = useForm();

  const messages = [
    "Analysing strategy...",
    "Checking indicators...",
    "Fetching best strategy...",
  ];
  const [loadingmsg, setloadingmsg] = useState(messages[0]);


  
  useEffect(()=>{

    setInterval(() => {
      const randomNum = Math.floor(Math.random() * 3);
      setloadingmsg(messages[randomNum])
    }, 4000);
    
    
      },[])
    
  const submit = async () => {
    var postddata = {
      data: {
     ... postdata, indicators:postdata.indicators.map((p:any)=>p.name)
      },
    };
    try {
      setisloading(true)
      var response = await onGenerateStrategy(postddata);
      setTimeout(() => {
        setStrategyresult(response);
        setisloading(false);
        nextStep();
      }, 3000);
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    }
  };
  return (
    <div>
      <div className="border border-1 grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-200   w-min-[400px] md:w-[700px]  mt-4 border-gray-300 rounded">
      <div className="  rounded  text-sm font-light p-2 ">
          <p className="">
            <b>Indicators:</b><ol><hr/> {postdata.indicators?.map((kik:any)=><li>{kik.name}</li>)}</ol>
          </p><hr/>
          <p className="">
            <b>Time Frame:</b> {postdata.timeframe}
          </p>
          <p className="">
            <b> Trade Time: </b>
            {postdata.tradetime}
          </p>
          
           
        </div>

        <div className="bg-white rounded  p-2 ">
          {isloading && (
            <div className=" m-12  justify-items-center justify-center">
              <PulseLoader
                className=" m-12 justify-center"
                color={"#350080"}
                loading={isloading}
                size={18}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p className=" flex animate-pulse justify-center items-center transition-all ease-in-out   delay-1000  duration-1000  w-[400px] text-gray-400 font-semibold ">
                {loadingmsg}
              </p>
            </div>
          )}
        </div>
      </div>

      <NavigateBtns
        shownext
        showprev
        islast
        actionPrev={PrevStep}
        nextCondition={!isloading}
        actionNext={async () => {
 await submit();  }}
      />
    </div>
  );
};
