import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StepWizard from "react-step-wizard";
import WizardStepIndicator from "wizard-step-indicator-react";
import Progressbar from "../../components/forms/Progressbar";
import Market from "./StepFlow/Market";
import TimeZone from "./StepFlow/TimeZone";
import Scanner from "./StepFlow/Scanner";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { GiRadarSweep } from "react-icons/gi";
import { PiClockFill } from "react-icons/pi";
import Result from "./StepFlow/SubFlows/Scanner/Result";

export default () => {
  const [postdata, setpostdata] = useState({});
  const { element, setpercentage } = Progressbar();
  const [mainActive, setmainActive] = useState<number>(1);

  useEffect(() => {
    setpercentage((mainActive / 3) * 100);
  }, [mainActive]);
  const headerindicators = [
    { name: "Market", icon: <LiaExchangeAltSolid className="inline" /> },
    { name: "TimeZone", icon: <PiClockFill className="inline" /> },
    { name: "Scanner", icon: <GiRadarSweep className="inline" /> },
  ];

  const IndicatorItem = ({
    icon,
    name,
    key_,
  }: {
    icon: JSX.Element;
    name: string;
    key_: number;
  }) => (
    <div
      className={`${
        mainActive == key_ + 1
          ? "!text-primary font-semibold"
          : " text-gray-400"
      } p-3  `}
    >
      {icon} <span className="ml-1">{name} </span>
    </div>
  );

  return (
    <div className="h-screen flex justify-center items-start  overflow-x-hidden overflow-y-scroll  ">
      <iframe
        src="https://tradetimescanner.com/indicator_test.html"
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};
