import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { Button } from "../forms";

export default ({ show, item }: any) => {
  return (
    <>
      <div className="ModalContainer shadow ">
        <div className=" flex flex-col  max-h-[80vh] overflow-y-scroll  rounded-[8px] bg-white  w-[80vw] md:w-[40vw] p-4 ">
          <p className="font-semibold self-start text-sm ">Scan details</p>
          {item.pairs?.map((pair: any) => (
            <span className="bg-blue-100 p-1 rounded mx-1 font-light">
              {" "}
              {pair}
            </span>
          ))}
          {item.items?.map((pair: any) => (
            <span className="bg-blue-100 p-1 rounded mx-1 font-light">
              {" "}
              {pair}
            </span>
          ))}

          <hr/>
          <ul>
          {item.details.map((det: any) => (
            <li className="  p-1 rounded mx-1 font-light">
              <p>{det}</p>
            </li>
          ))}
</ul>
          <div className=" flex justify-center items-center w-full gap-6">
            <Button
              outlined
              text="Close"
              onBtnClick={() => {
                show(false);
              }}
            />

           
          </div>
        </div>
      </div>
    </>
  );
};
