import { MdAnalytics, MdDashboard } from "react-icons/md";
import { RiExchangeFundsFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { VscGithubAction } from "react-icons/vsc";

const sidebarItems = [
  {
    name: "Dashboard",
    title: "/",
    url: "/dashboard",
    icon: <MdDashboard className="inline" />,
  },
  {
    name: "Analysis",
    url: "/analysis",
    title: "/analysis",
    icon: <MdAnalytics className="inline" />,
  },
  {
    name: "Strategy Generator",
    url: "/strategy",
    title: "/strategy",
    icon: <VscGithubAction className="inline" />,
  },
  {
    name: "Join Telegram",
    url: "https://t.me/tradetimescanner",
    title: "/telegram",
    icon: <FaTelegramPlane className="inline" />,
  },
];

  const indicators = [
  {
    categoryname: "Options Trading Indicators",
    items: [
      { name: "Accelerator Oscillator", code: 1 },
      { name: "ADX", code: 2 },
      { name: "Alligator", code: 3 },
      { name: "Aroon", code: 4 },
      { name: "Average True Range", code: 5 },
      { name: "Awesome Oscillator", code: 6 },
      { name: "Bears Power", code: 7 },
      { name: "Bollinger Bands", code: 8 },
      { name: "Bollinger Bands Width", code: 9 },
      { name: "Bulls Power", code: 10 },
      { name: "CCI", code: 11 },
      { name: "Donchian Channels", code: 12 },
      { name: "DeMarker", code: 13 },
      { name: "Envelopes", code: 14 },
      { name: "Fractal", code: 15 },
      { name: "Fractal Chaos Bands", code: 16 },
      { name: "Ichimoku Kinko Hyo", code: 17 },
      { name: "Keltner Channel", code: 18 },
      { name: "MACD", code: 19 },
      { name: "Momentum", code: 20 },
      { name: "Moving Average", code: 21 },
      { name: "OsMA", code: 22 },
      { name: "Parabolic SAR", code: 23 },
      { name: "RSI", code: 24 },
      { name: "Rate of Change", code: 25 },
      { name: "Schaff Trend Cycle", code: 26 },
      { name: "Stochastic Oscillator", code: 27 },
      { name: "SuperTrend", code: 28 },
      { name: "Vortex", code: 29 },
      { name: "Williams %R", code: 30 },
      { name: "ZigZag", code: 31 }
    ]
  },
  {
    categoryname: "Trend Indicators",
    items: [
      { name: "Moving Averages (Simple, Exponential, Weighted, Hull, etc.)", code: 32 },
      { name: "Bollinger Bands", code: 33 },
      { name: "Parabolic SAR", code: 34 },
      { name: "Ichimoku Cloud", code: 35 }
    ]
  },
  {
    categoryname: "Oscillators",
    items: [
      { name: "Relative Strength Index (RSI)", code: 36 },
      { name: "Stochastic Oscillator", code: 37 },
      { name: "Moving Average Convergence Divergence (MACD)", code: 38 },
      { name: "Commodity Channel Index (CCI)", code: 39 },
      { name: "Williams %R", code: 40 }
    ]
  },
  {
    categoryname: "Volume Indicators",
    items: [
      { name: "On-Balance Volume (OBV)", code: 41 },
      { name: "Volume Profile (Visible Range, Session, etc.)", code: 42 },
      { name: "Accumulation/Distribution Line (A/D Line)", code: 43 },
      { name: "Chaikin Money Flow (CMF)", code: 44 }
    ]
  },
  {
    categoryname: "Volatility Indicators",
    items: [
      { name: "Average True Range (ATR)", code: 45 },
      { name: "Keltner Channels", code: 46 },
      { name: "Donchian Channels", code: 47 }
    ]
  },
  {
    categoryname: "Support and Resistance",
    items: [
      { name: "Pivot Points (Standard, Fibonacci, Woodie’s, etc.)", code: 48 },
      { name: "Fibonacci Retracement/Extension Tools", code: 49 }
    ]
  }
];


export { sidebarItems, indicators };
